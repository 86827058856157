$(document).on("turbolinks:load", function() {
	//スムーススクロール
	$('a[href^="#"]').click(function() {
		var speed = 400; // ミリ秒
		var href= $(this).attr("href");
		var target = $(href == "#" || href == "" ? 'html' : href);
		var position = target.offset().top;
		$('body,html').animate({scrollTop:position}, speed, 'swing');
		return false;
	});
	//アコーディオン01
	//acordion_treeを一旦非表示に
	$(".ctg_acordion_tree").css("display","none");
	//triggerをクリックすると以下を実行
	$(".ctg_trigger").click(function(){
		//もしもクリックしたtriggerの直後の.acordion_treeが非表示なら
		if($("+.ctg_acordion_tree",this).css("display")=="none"){
			//classにactiveを追加
			$(this).addClass("active");
			//直後のacordion_treeをスライドダウン
			$("+.ctg_acordion_tree",this).slideDown("normal");
		}else{
			//classからactiveを削除
			$(this).removeClass("active");
			//クリックしたtriggerの直後の.acordion_treeが表示されていればスライドアップ
			$("+.ctg_acordion_tree",this).slideUp("normal");
		}
	});
	//アコーディオン02
	//triggerをクリックすると以下を実行
	$(".trigger").click(function(){
		//もしもクリックしたtriggerの直後の.acordion_treeが非表示なら
		if($("+.acordion_tree",this).css("display")=="none"){
			//classにactiveを追加
			$(this).addClass("active");
			//直後のacordion_treeをクラス追加
			$("+.acordion_tree",this).addClass("active");
		}else{
			//classからactiveを削除
			$(this).removeClass("active");
			//クリックしたtriggerの直後の.acordion_treeが表示されていればクラス削除
			$("+.acordion_tree",this).removeClass("active");
		}
	});
});


//ボックスごとリンク
$(document).on("turbolinks:load", function() {
	$(".searchResultListBox .oneBox").click(function() {
        window.location = $(this).find('a').attr('href');
        return false;
    });
});

//PC用表示切り替え
$(document).on("turbolinks:load", function() {
	$("#modeBox").hide();
	if($.cookie("mode")=="pc"){ // PC用表示とCookieに記録されていたら
		$("#modeBox").show();
		$("meta[name=viewport]").attr("content","width=980");
	}else if($.cookie("mode")=="sp"){
		$("#modeBox").show();
	}
	$("#modeBox dl dd.pc").click(function(){
		$("meta[name=viewport]").attr("content","width=980");
		$.cookie("mode","pc",{path:'/'}); // PC用表示とCookieに記録
	});
	$("#modeBox dl dd.sp").click(function(){
		$("#modeBox").show();
		$("meta[name=viewport]").attr("content","width=device-width,maximum-scale=1.0, minimum-scale=1.0");
		$.cookie("mode","sp",{path:'/'}); // スマホ用表示とCookieに記録
	});
});

//個人情報同意
$(document).on("turbolinks:load", function() {
	$("#privacyAgreeNotion").hide();
	$("#privacyBtnNext01 , #contactBtnNext01 , #legoBtnNext01").click(function () {
		var val = $(':radio[name="privacyAgree"]:checked').val();
		if(val == 'notagree'){
			$("#privacyAgreeNotion").show();
			return false;
		}
    });
});

$(document).on("turbolinks:load", function() {
    $("body").on('click', "#js-searchLetterButton", function(){
      $("#js-searchLetterBox").toggle();
    });
    $('body').on('mouseover', '.searchLetter > a', function(e){
      $(e.currentTarget).parent().css("background", "#aaa");
    });
    $('body').on('mouseout', '.searchLetter > a', function(e){
      $(e.currentTarget).parent().css("background", "#ccc");
    });
});
