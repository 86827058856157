document.addEventListener("turbolinks:load", function() {
  $('#topSlideBox ul').bxSlider({
	  mode: 'fade',
    pause: 5000,
    speed: 1000,
    auto: true,
    pager: true,
    controls: false
  });
});

$(document).on("turbolinks:load", function() {
  $('#entry_back').on("click", function(e) {
    return e.currentTarget.form.action = "/entry"
  })

  $('#ev3_price_open').on("click", function() {
    $("#ev3_price_list").show()
  })
  $('#ev3_price_close').on("click", function() {
    $("#ev3_price_list").hide()
  })

  $('#spike_price_open').on("click", function() {
    $("#spike_price_list").show()
  })
  $('#spike_price_close').on("click", function() {
    $("#spike_price_list").hide()
  })

  $('#sci_price_open').on("click", function() {
    $("#sci_price_list").show()
  })
  $('#sci_price_close').on("click", function() {
    $("#sci_price_list").hide()
  })

  $('#story_price_open').on("click", function() {
    $("#story_price_list").show()
  })
  $('#story_price_close').on("click", function() {
    $("#story_price_list").hide()
  })

  $('#wedo_price_open').on("click", function() {
    $("#wedo_price_list").show()
  })
  $('#wedo_price_close').on("click", function() {
    $("#wedo_price_list").hide()
  })

  $('#bricq_price_open').on("click", function() {
    $("#bricq_price_list").show()
  })
  $('#bricq_price_close').on("click", function() {
    $("#bricq_price_list").hide()
  })

  $('#nursery_price_open').on("click", function() {
    $("#nursery_price_list").show()
  })
  $('#nursery_price_close').on("click", function() {
    $("#nursery_price_list").hide()
  })

  $('#calc_lego_request').on("click", function() {
    var items, prices, item, price
    items  = 0
    prices = 0
    _.each($(".lego_profile"), function(input, i) {
      item   = parseInt($(input).find(".request_lego").val())
      items  = items + item
      price  = parseInt($(input).find(".lego_price").val())
      prices = prices + price * item
    })
    $("#total_number").text(items)
    $("#total_price").text(prices.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))
  })
});

$(document).on("turbolinks:load", function() {
  var change_yomi = function() {
    var select = $('#yomi_selector');
    $("#loading").show();
    var member_id = location.pathname.split('/')[3];
    var get_yomi_url;
    if (location.pathname.split('/')[2] === "members") {
      get_yomi_url = "/area_occupations/" + ($('#area_selector').val()) + "/yomi.json?member_id=" + member_id;
    } else {
      get_yomi_url = "/area_occupations/" + ($('#area_selector').val()) + "/yomi.json";
    }

    return $.get(get_yomi_url).done(function(response) {
      $('#store_selector').empty();
      $('#store_selector').append($("<option value=''>").text("").attr({
        selected: true
      }));
      $("#loading").hide();
      select.empty();
      select.append($("<option value=''>").text("選択"));

      var ref = response.yomi;
      var results = [];
      var i, len
      for (i = 0, len = ref.length; i < len; i++) {
        var object = ref[i];
        results.push((function(object) {
          return select.append($("<option>").val("" + object).text("" + object));
        })(object));
      }
      return results;
    });
  };

  return $('body').on("change", "#area_selector", change_yomi);
});

$(document).on("turbolinks:load", function() {
  var change_store = function(store_id) {
    var select = $('#store_selector');
    var encoded_yomi = encodeURI($('#yomi_selector').val());

    return $.get("/area_occupations/" + ($('#area_selector').val()) + "/" + encoded_yomi + "/yomi_stores.json").done(function(response) {
      select.empty();
      select.append($("<option value=''>").text("選択"));
      var ref = response.store;
      var results = [];
      var i, len, store_name;
      for (i = 0, len = ref.length; i < len; i++) {
        var object = ref[i];
        results.push((function(object) {
          if (object.name.indexOf('協同組合') !== -1) {
            store_name = object.name.split('協同組合')[1];
          } else if (object.name.indexOf('インターカレッジコープ') !== -1) {
            store_name = object.name.split('インターカレッジコープ')[1];
          } else {
            store_name = object.name;
          }
          var option = $("<option>").val(object.id).text(object.univ_name + " " + store_name);
          select.append(option);
          if (object.id === parseInt(store_id)) {
            return option.attr({
              selected: true
            });
          }
        })(object));
      }
      return results;
    });
  };

  return $('body').on("change", "#yomi_selector", change_store);
  if ($("#yomi_selector").val()) {
    return change_store($("#js_store_id").text());
  }
});

$(document).on("turbolinks:load", function() {
  $('.open_icon_page_window').on("click", function() {
    return window.open('https://software.univcoop.or.jp/icon.html','iconwindow','width=600,height=800')
  })

  $('body').on("change", ".order_sheet_selector", function() {
    if ($(".order_sheet_selector").val() === "") {
      return $(".order_sheet_select_btn").attr({
        disabled: "disabled"
      });
    } else {
      return $(".order_sheet_select_btn").removeAttr("disabled");
    }
  })

  $('#clear_search_day_type').on("click", function() {
    return [
      $('.search_day_radio').prop('checked', false),
      $('.search_day_form').attr('value', ""),
      $('.search_day_form').datetimepicker('reset')
    ]
  })

  $('#clear_open_enabled_radio').on("click", function() {
    $('.open_enabled_radio').prop('checked', false)
  })
});

$(document).on("turbolinks:load", function() {
   var change_news_category;

  change_news_category = function() {
    var get_news_category, select;
    select = $('#news_category_selector');
    get_news_category = "/news/page/" + ($('#unit_page_news_introduction_id').val()) + "/categories.json";
    return $.get(get_news_category).done(function(response) {
      var i, len, object, ref, results;
      $("#loading").hide();
      select.empty();
      select.append($("<option>").text("選択"));
      ref = response.categories;
      results = [];
      for (i = 0, len = ref.length; i < len; i++) {
        object = ref[i];
        results.push((function(object) {
          return select.append($("<option>").val("" + object.id).text("" + object.name));
        })(object));
      }
      return results;
    });
  };

  $('body').on("change", "#unit_page_news_introduction_id", change_news_category);

  if ($("#unit_page_news_introduction_id").val()) {
    change_news_category();
  }
})

$(document).on("turbolinks:load", function() {
  Flipsnap('.topCampaignBox ul');
});
